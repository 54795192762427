import React from "react";

import SVG from "../Svg";

const WeChatIcon = () => (
  <SVG width="16" height="17" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4391 12.7839C14.3916 12.0882 15.0001 11.059 15.0001 9.91552C15.0001 7.8196 12.9774 6.12085 10.482 6.12085C7.98685 6.12085 5.96387 7.8196 5.96387 9.91552C5.96387 12.0114 7.98685 13.7105 10.482 13.7105C10.9975 13.7105 11.4953 13.6362 11.9569 13.5025C11.9989 13.4896 12.0434 13.4824 12.0897 13.4824C12.1764 13.4824 12.2552 13.509 12.3296 13.5524L13.3185 14.128C13.3462 14.1438 13.373 14.1563 13.4057 14.1563C13.489 14.1563 13.5563 14.0882 13.5563 14.0045C13.5563 13.9668 13.5414 13.9294 13.532 13.8934C13.5263 13.872 13.4046 13.4139 13.3284 13.128C13.3197 13.0958 13.3125 13.0648 13.3125 13.0313C13.3125 12.9294 13.3625 12.839 13.4391 12.7839ZM8.97591 9.26943C8.66444 9.26943 8.41179 9.01501 8.41179 8.7011C8.41179 8.38705 8.66444 8.1325 8.97591 8.1325C9.28751 8.1325 9.54003 8.38705 9.54003 8.7011C9.54003 9.01501 9.28751 9.26943 8.97591 9.26943ZM11.9877 9.26943C11.6761 9.26943 11.4238 9.01501 11.4238 8.7011C11.4238 8.38705 11.6761 8.1325 11.9877 8.1325C12.2993 8.1325 12.5518 8.38705 12.5518 8.7011C12.5518 9.01501 12.2993 9.26943 11.9877 9.26943Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.4217 1.93091C2.42736 1.93091 0 3.96978 0 6.48462C0 7.85696 0.730382 9.0921 1.87329 9.92681C1.96512 9.99282 2.02516 10.1011 2.02516 10.2237C2.02516 10.2641 2.0166 10.3009 2.006 10.3397C1.91472 10.683 1.76857 11.2326 1.76177 11.2583C1.75036 11.3015 1.73271 11.3463 1.73271 11.3914C1.73271 11.492 1.81353 11.5734 1.91337 11.5734C1.95276 11.5734 1.98468 11.5589 2.01769 11.5396L3.20488 10.8489C3.29399 10.7971 3.38839 10.7652 3.49258 10.7652C3.54813 10.7652 3.60152 10.7735 3.65191 10.7889C4.20571 10.9495 4.80297 11.0386 5.4217 11.0386C5.52167 11.0386 5.62083 11.0359 5.71945 11.0316C5.60154 10.6762 5.53743 10.3023 5.53743 9.9154C5.53743 7.62183 7.75113 5.76226 10.4821 5.76226C10.581 5.76226 10.6062 5.76538 10.7036 5.77014C10.2953 3.59461 8.12657 1.93091 5.4217 1.93091ZM3.615 5.67333C3.26074 5.67333 2.97372 5.384 2.97372 5.0273C2.97372 4.67046 3.26074 4.38127 3.615 4.38127C3.96871 4.38127 4.25573 4.67046 4.25573 5.0273C4.25573 5.384 3.96871 5.67333 3.615 5.67333ZM7.22916 5.67319C6.87517 5.67319 6.58816 5.384 6.58816 5.0273C6.58816 4.67046 6.87517 4.38127 7.22916 4.38127C7.58314 4.38127 7.87016 4.67046 7.87016 5.0273C7.87016 5.384 7.58314 5.67319 7.22916 5.67319Z"
      fill="currentColor"
    />
  </SVG>
);

export default WeChatIcon;
